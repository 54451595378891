import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PageTitle from '../../components/main/PageTitle';
import Header from '../../components/header/Header';
import Footer from "../../components/footer/Footer";
import CrossImg from "../../images/cross.png";
import ArrowImg from "../../images/arrow-quiz.png";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_PATH } from '../../../Variables';

const quizQuestions = [
  {
    question: "Jaki jest termin przyznawania punktów za zakupy produktów Yetico w programie HiYetico?",
    answers: ["Punkty przyznawane są za zakupy zrobione do 20 października", "Punkty przyznawane są za zakupy zrobione do końca 2024 roku","Punkty przyznawane są cały czas, program HiYetico nie wygasa"],
    correctAnswer: "Punkty przyznawane są za zakupy zrobione do 20 października"
  },
  {
    question: "W jaki sposób można zamienić zebrane punkty na nagrody?",
    answers: ["Należy napisać e-mail z wybraną nagrodą do handlowca Yetico", 'Należy zamówić nagrody poprzez platformę promocyjną www.hiyetico.pl, w zakładce „Nagrody”', "Należy zadzwonić do działu marketingu Yetico i złożyć zamówienie na wybrane nagrody"],
    correctAnswer: 'Należy zamówić nagrody poprzez platformę promocyjną www.hiyetico.pl, w zakładce „Nagrody”'
  },
  {
    question: "Jaki jest limit nagród przypadających dla jednego uczestnika promocji?",
    answers: ["Jeden uczestnik promocji może odebrać jedną nagrodę za zgromadzone punkty", "Jeden uczestnik promocji może odebrać do 4 nagród za zgromadzone punkty", "Jeden uczestnik może zamówić nieograniczoną liczbę nagród, których wartość punktowa nie przekracza liczby zebranych punktów"],
    correctAnswer: "Jeden uczestnik może zamówić nieograniczoną liczbę nagród, których wartość punktowa nie przekracza liczby zebranych punktów"
  },
  {
    question: "Czy zebrane punkty można wymienić na ekwiwalent pieniężny?",
    answers: ["Tak, można wymienić punkty na ekwiwalent pieniężny", "Można wymienić tyko połowę punktów na pieniądze, a drugą połowę punktów na nagrody z katalogu nagród HiYetico", "Punkty można wymienić tylko na nagrody z katalogu nagród HiYetico"],
    correctAnswer: "Punkty można wymienić tylko na nagrody z katalogu nagród HiYetico"
  },
  {
    question: "Jaki jest czas na wymianę punktów na nagrody?",
    answers: ["Czas jest nieograniczony", "Dwa tygodnie od dnia, kiedy kończy się przyznawanie punktów za zakupy, tj. 2 tygodnie od 21 października 2024", "Do końca 2024 roku"],
    correctAnswer: "Dwa tygodnie od dnia, kiedy kończy się przyznawanie punktów za zakupy, tj. 2 tygodnie od 21 października 2024"
  },
  {
    question: "Co się dzieje z punktami, które nie zostaną wymienione na nagrody?",
    answers: ["Punkty przepadają", "Punkty przechodzą na następny rok"],
    correctAnswer: "Punkty przepadają"
  }
];

const validationSchema = Yup.object({
  answer: Yup.string().required("Proszę zaznaczyć odpowiedź!"),
});

const QuizComponent = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
  const [userAnswers, setUserAnswers] = useState([]);
  const [attemptCount, setAttemptCount] = useState(0);
  const [incorrectAnswers, setIncorrectAnswers] = useState([]);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [chances, setChances] = useState(3); // domyślnie 3 szanse
  const [additionalPrize, setAdditionalPrize] = useState(null);
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    if (user && user.id) {
      fetchChances(user.id);
    }
  }, [user]);

  const checkAdditionalPrize = async () => {
    try {
      const response = await axios.get(`${API_PATH}/check-additional-price-quiz-5`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
  
      setAdditionalPrize(response.data);
    } catch (error) {
      console.error('Error checking additional prize:', error);
    }
  };  

  const fetchChances = async (userId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${API_PATH}/quiz5-chances`, { user_id: userId }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.data) {
        setChances(response.data.chances);
      }
    } catch (error) {
      console.error('Error fetching chances from API:', error);
    }
  };

  const updateChances = async (userId, newChances) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${API_PATH}/quiz5-chances`, {
        user_id: userId,
        chances: newChances
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setChances(newChances);
    } catch (error) {
      console.error('Error updating chances in API:', error);
    }
  };

  const sendQuizResultToAPI = async (userId) => {
    const passed = true;
    const passedDate = new Date().toISOString().split('T')[0]; // aktualna data w formacie YYYY-MM-DD

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${API_PATH}/quiz5data`, {
        user_id: userId,
        passed: passed,
        passed_date: passedDate
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      console.log('Response from API:', response.data);
    } catch (error) {
      console.error('Error sending data to API:', error);
    }
  };

  const sendQuizFailedToAPI = async (userId) => {
    try {
      const token = localStorage.getItem('token');
      const passedDate = new Date().toISOString().split('T')[0]; // aktualna data w formacie YYYY-MM-DD
      const response = await axios.post(`${API_PATH}/quiz5data-failed`, {
        user_id: userId,
        passed_date: passedDate
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      console.log('Response from API (failed):', response.data);
    } catch (error) {
      console.error('Error sending data to API (failed):', error);
    }
  };

  const handleSubmit = (values) => {
    const answers = [...userAnswers];
    answers[currentQuestionIndex] = values.answer;
    setUserAnswers(answers);
  
    if (currentQuestionIndex < quizQuestions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      const incorrect = quizQuestions
        .map((q, index) => q.correctAnswer !== answers[index] ? index + 1 : null)
        .filter(index => index !== null);
  
      setIncorrectAnswers(incorrect);
      setAttemptCount(attemptCount + 1);
  
      setQuizCompleted(true);
  
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.id) {
        if (incorrect.length === 0) {
          sendQuizResultToAPI(user.id).then(() => checkAdditionalPrize());
        } else {
          const remainingChances = chances - 1;
          updateChances(user.id, remainingChances);
  
          if (remainingChances <= 0) {
            sendQuizFailedToAPI(user.id);
          }
        }
      }
    }
  };  

  const handleRetry = () => {
    setUserAnswers([]);
    setCurrentQuestionIndex(-1);
    setIncorrectAnswers([]);
    setQuizCompleted(false);
  };

  const getSzansaText = (count) => {
    if (count === 1) return "szansę";
    if (count > 1 && count < 5) return "szanse";
    return "szans";
  };

  const renderResults = () => {
    if (incorrectAnswers.length === 0) {
      return (
        <div className='quiz-wrapper'>
          <>
            <p>Gratulujemy!</p>
            <p>Test ukończony pomyślnie.</p>
            <p className='quiz-text-space'>Extra 5 punktów leci na Twoje konto.</p>
          </>
          <button className='btn-quiz-retry' onClick={handleGoBack}>Wracam do listy quizów</button>
        </div>
      );
    } else if (chances <= 0) {
      return (
        <div className='quiz-wrapper'>
          <p>Twoje szanse się skończyły.</p>
          <p>Powodzenia w następnych quizach!</p>
          <button className='btn-quiz-retry' onClick={handleGoBack}>Powrót</button>
        </div>
      );
    } else {
      return (
        <div className='quiz-wrapper'>
          <p>Niestety tym razem Ci się nie udało.</p>
          <p>Masz jeszcze {chances} {getSzansaText(chances)}.</p>
          <p className='quiz-red'>Numery pytań z błędną odpowiedzią: <br /> {incorrectAnswers.join(", ")}</p>
          <button className='btn-quiz-retry' onClick={handleRetry}>Poprawiam odpowiedzi</button>
        </div>
      );
    }
  };

  const closeQuiz = () => {
    navigate('/quizzes');
  }
  const handleGoBack = () => {
    navigate('/quizzes');
  }

  return (
    <>
      <Header />
      <div className='quizzes'>
        <div className='quizzes__inner-single'>
          <PageTitle title="Quiz 1" />
          {currentQuestionIndex === -1 ? (
            <div className='quiz-wrapper'>
              <button className='close-btn' onClick={closeQuiz}><img src={CrossImg} alt="Close" /></button>
              <h2>Quiz 5</h2>
              <span className='quiz-start-text'>Kliknij by rozpocząć</span>
              <button className='btn-quiz btn-quiz-start' onClick={() => setCurrentQuestionIndex(0)}>
                <img src={ArrowImg} alt='Dalej' className='arrow-quiz arrow-quiz-right' />
              </button>
            </div>
          ) : quizCompleted ? (
            renderResults()
          ) : (
            <Formik
              key={currentQuestionIndex}
              initialValues={{ answer: userAnswers[currentQuestionIndex] || '' }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <button className='close-btn' onClick={closeQuiz}><img src={CrossImg} alt="Close" /></button>
                <h2 className='quiz-question'>{currentQuestionIndex + 1}. {quizQuestions[currentQuestionIndex].question}</h2>
                {quizQuestions[currentQuestionIndex].answers.map((answer, index) => (
                  <div key={index} className='quiz-answer'>
                    <Field type="radio" name="answer" value={answer} id={`answer${index}`} />
                    <label htmlFor={`answer${index}`}>{answer}</label>
                  </div>
                ))}
                <ErrorMessage name="answer" component="div" className="error" />
                <div>
                  {currentQuestionIndex >= 0 && (
                    <button type="button" onClick={() => currentQuestionIndex === 0 ? setCurrentQuestionIndex(-1) : setCurrentQuestionIndex(currentQuestionIndex - 1)} className='btn-quiz btn-quiz-back'>
                      <img src={ArrowImg} alt='Cofnij' className='arrow-quiz arrow-quiz-left' />
                    </button>
                  )}
                  <button type="submit" className='btn-quiz btn-quiz-next'>
                    <img src={ArrowImg} alt={currentQuestionIndex === quizQuestions.length - 1 ? 'Zakończ' : 'Dalej'} className='arrow-quiz arrow-quiz-right' />
                  </button>
                </div>
              </Form>
            </Formik>
          )}
          {currentQuestionIndex >= 0 && !quizCompleted && (
            <div className='quiz-steps'><span className='span-blue'>{currentQuestionIndex + 1} /</span> {quizQuestions.length}</div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default QuizComponent;