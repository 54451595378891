import WelcomeSection from './components/WelcomeSection';
import SectionRulesSection from './components/ActionRulesSection';
import AwardsSection from './components/AwardsSection';
import ExtraPointsSection from './components/ExtraPointsSection';
import TripSection from './components/TripSection';
import PopupSection from './components/PopupSection';
import ContactSection from './components/ContactSection';
import Footer from '../../components/footer/Footer';

function Home() {
    return (
      <>
        <PopupSection />
        <WelcomeSection />
        <SectionRulesSection />
        <AwardsSection />
        <ExtraPointsSection />
        <TripSection />
        <ContactSection />
        <Footer />
      </>   
    );
  }
  
export default Home;