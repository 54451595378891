import React from "react";
import BackgroundImage from "../../../images/sygnet_yetico.png";
import IwaszczynVideo from "../../../videos/Iwaszczyszyn.mp4";
import CzuryszkiewiczVideo from "../../../videos/Czuryszkiewicz.mp4";

function CompetitionRulesSection() {
  return (
    <section className="competition-rules" id="competition-rules">
      <div className="competition-rules__inner">
        <h2 className="competition-rules__title">Zwycięzcy konkursu</h2>
        <div className="competition-rules__wrapper">
         Pan G. Iwaszczyszyn
          <video width="200" height="320" controls controlsList="nodownload" disablePictureInPicture>
            <source src={IwaszczynVideo} type="video/mp4" />
            Pan G. Iwaszczyszyn
          </video>
          <br /><br />
          Pan G. Czuryszkiewicz
          <video width="340" height="200" controls controlsList="nodownload" disablePictureInPicture>
            <source src={CzuryszkiewiczVideo} type="video/mp4" />
            Pan G. Czuryszkiewicz
          </video>
        </div>
        <h2 className="competition-rules__title">Gratulujemy!</h2>
        <img
          src={BackgroundImage}
          alt="Logo Yetico"
          className="section-rules__image-bg competition-rules__image_bg"
        />
      </div>
    </section>
  );
}

export default CompetitionRulesSection;
