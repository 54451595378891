import BackgroundImage from '../../../images/sygnet_yetico.png';
import { useNavigate } from 'react-router-dom';

function SectionRulesSection() {

    const navigate = useNavigate();

    const handleCheckAward = () => {
        navigate('/awards'); // Navigate to the link destination
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
    }
    
    return (
        <section className="section-rules" id="actions-rules">
            <div className="section-rules__inner">
                <h2 className="section-rules__title">Zasady akcji</h2>
                <p className="section-rules__subtitle">Zasady są proste: kupujesz, zbierasz punkty i zyskujesz.</p>
                <div className="section-rules__wrapper">
                    <div className="section-rules__container">
                        <h2>1. KUPUJESZ</h2>
                        <div className="line"></div>
                        <p>Kupuj produkty Yetico objęte promocją* w okresie <strong>od 4.06. do 20.10.2024 roku</strong></p>
                        <p className="text2">*Produkty objęte promocją: wszystkie płyty EPS Yetico, płyty XPS Finnfoam, Styrodur, piany, kleje, siatka, zaślepki.</p>
                    </div>
                    <div className="section-rules__container">
                        <h2>2. ZBIERASZ PUNKTY</h2>
                        <div className="line"></div>
                        <p>Każde 10 000 zł wydane na produkty promocyjne w czasie trwania akcji to 10 punktów.</p>
                        <button className="section-rules__button" onClick={handleCheckAward}>Sprawdzam nagrody</button>
                    </div>
                    <div className='rules-space-div'></div>
                    <div className="section-rules__container">
                        <h2>3. ZYSKUJESZ</h2>
                        <div className="line"></div>
                        <p>Punkty wymieniasz na atrakcyjne nagrody. Nagrody przypisane do 5 progów zakupowych. Przekroczenie kolejnych progów odblokuje nagrody o coraz wyższych wartościach</p>
                    </div>
                    <div className="section-rules__container container--last">
                        <h2>DODATKOWE INFORMACJE</h2>
                        <div className="line"></div>
                        <p>Punkty aktualizujemy co dwa tygodnie, w poniedziałki.</p>
                        <p>
                            WAŻNE DATY<br />
                            <strong>04.06.2024</strong> – start akcji<br />
                            <strong>20.10.2024</strong> – zakończenie etapu zbierania punktów<br />
                            <strong>21.10-05.11.2024</strong> – zamawianie nagród<br />
                            <strong>04.11.-18.11.2024</strong> – wysyłka nagród<br />
                            <strong>do 30.11.2024</strong> – zakończenie zbierania punktów w przypadku przedłużenia uczestnictwa<br />
                            <strong>03.12-09.12.2024</strong> – zamawianie nagród w przypadku przedłużenia uczestnictwa<br />
                            <strong>10.12.2024-10.01.2025</strong> – wysyłka nagród w przypadku przedłużenia uczestnictwa<br />
                        </p>
                    </div>
                </div>
            </div>
            <img src={BackgroundImage} alt="Logo Yetico" className="section-rules__image-bg" />
            <img src={BackgroundImage} alt="Logo Yetico" className="section-rules__image-bg--mobile" />
        </section>
    );
  }
  
export default SectionRulesSection;