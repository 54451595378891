import {useNavigate} from 'react-router-dom';
import axios from "axios";
import {API_PATH} from "../../../../Variables";
import React, { useState } from 'react';
import RegulaminProgramu from '../../../files/regulamin.pdf';

function PopupSection() {

    const navigate = useNavigate();

    const userJson = localStorage.getItem('user');
    let user = JSON.parse(userJson);
    const userId = user.id;
    const token = localStorage.getItem('token');

    const [showPopup, setShowPopup] = useState(user.popup_status)
    const [isChecked, setIsChecked] = useState(false)
    const [showError, setShowError] = useState(false)

    const checkHandler = () => {
        setIsChecked(!isChecked)
        setShowError(false)
    }

    const handleContinue = async () => {
        setShowError(false);
        if(!isChecked) {
            setShowError(true);
        } else {
            try {
                const response = await axios.put(`${API_PATH}/user/${userId}/competition-continue`, {}, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                if (response.data.status === "success") {
                    localStorage.removeItem('user')
                    localStorage.setItem('user', JSON.stringify(response.data.user));
                    setShowPopup(2);
                }
            } catch (error) {

            }
        }
    }
    const handleNonContinue = async () => {
        setShowError(false);
        if(!isChecked) {
            setShowError(true);
        } else {
            try {
                const response = await axios.put(`${API_PATH}/user/${userId}/competition-discontinue`, {}, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                if (response.data.status === "success") {
                    localStorage.removeItem('user')
                    localStorage.setItem('user', JSON.stringify(response.data.user));
                    setShowPopup(2);
                }
            } catch (error) {

            }
        }
    }

    return (showPopup == 1 || showPopup == undefined) && (
        <section className="overlay">
            <div className="popup">
                <h2>Drogi uczestniku</h2>
                <div className="content">
                    Drogi Uczestniku! Doceniamy Twoje<br/>
                    zaangażowanie w sprzedaż produktów <br/>
                    Yetico, dlatego chcemy dać Ci wybór - <br/>
                    możesz zakończyć zbieranie punktów <br />
                    już teraz lub nadal zbierać punkty<br/>
                    do 30 listopada.
                </div>
                <div className="rule">
                    <label for="rule-1">
                        <input type="checkbox"
                               checked={isChecked}
                               onChange={checkHandler} id="rule-1"/> Potwierdzam, że zapoznałem się<br/>z zaktualizowanym <a target="_blank"
                        href={RegulaminProgramu}> Regulaminem</a>.
                    </label>
                    {showError && <div className="rulesErrorNotification"><br />Musisz zapoznać się z regulaminem</div>}
                </div>
                <div className="content">
                    Zdecyduj i wybierz:
                </div>
                <div className="buttons">
                    <button onClick={handleContinue} className="button buttonYellow" id="button-continue">Zbieram punkty dalej</button>
                    <button onClick={handleNonContinue} className="button buttonWhite" id="button-discontinue">Kończę zbieranie punktów<br />i wybieram nagrody</button>
                </div>
            </div>
        </section>
    );
}

export default PopupSection;