import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, CircularProgress } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Nav from '../../components/Nav';
import { API_PATH } from '../../../../Variables';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true); // State to manage loading
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${API_PATH}/users`, {
          headers: {
            Authorization: `Bearer ${token}`
        }
        });
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    fetchUsers();
  }, [token]);

  const handleEdit = (userId) => {
    navigate(`/admin/user-edit/${userId}`);
  };

  const handleDetails = (userId) => {
    navigate(`/admin/user-details/${userId}`);
  };

  return (
    <>
      <Nav />
      <TableContainer component={Paper} sx={{ margin: '20px auto', padding: '20px', maxWidth: '80%' }}>
        {loading ? ( // Show loader while loading
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
          </div>
        ) : (
          <Table>
            <TableHead sx={{ backgroundColor: '#f5f5f5' }}>
              <TableRow>
                <TableCell>Imię</TableCell>
                <TableCell>Nazwisko</TableCell>
                <TableCell>Adres e-mail</TableCell>
                <TableCell>Numer telefonu</TableCell>
                <TableCell>Nazwa firmy</TableCell>
                <TableCell>Czy zgłoszenie wysłane</TableCell>
                <TableCell>Ostatnie logowanie</TableCell>
                <TableCell>Zbiera punkty?</TableCell>
                <TableCell>Akcje</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user, index) => (
                <TableRow key={user.id} sx={{ backgroundColor: index % 2 ? '#f9f9f9' : '#fff' }}>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.lastname}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.phone_number}</TableCell>
                  <TableCell>{user.company_name}</TableCell>
                  <TableCell>
                    {user.file_uploaded ? (
                      <CheckCircleIcon sx={{ color: 'green' }} />
                    ) : (
                      <CancelIcon sx={{ color: 'red' }} />
                    )}
                  </TableCell>
                  <TableCell>{user.last_login_date ? user.last_login_date : 'Użytkownik jeszcze się nie zalogował.'}</TableCell>
                  <TableCell>{user.popup_status == 1 ? 'Brak decyzji' : user.popup_status == 4 ? 'Minął termin' : user.popup_status == 2 ? 'TAK' : 'NIE'}</TableCell>
                  <TableCell>
                    <Button variant="contained" color="primary" onClick={() => handleEdit(user.id)} style={{ marginBlock: '10px', marginRight: '10px' }}>
                      Edytuj
                    </Button>
                    <Button variant="contained" color="primary" onClick={() => handleDetails(user.id)}>
                      Szczegóły
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </>
  );
};

export default Users;