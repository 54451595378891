import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PageTitle from '../../components/main/PageTitle';
import ArrowLeftImg from '../../images/long_arrow_left.png';
import axios from 'axios';
import Modal from 'react-modal';
import { API_PATH } from '../../../Variables';

Modal.setAppElement('#root');

const Summary = () => {
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [points, setPoints] = useState(0);
  const [insufficientPointsModal, setInsufficientPointsModal] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const quantity = queryParams.get('quantity');
  const awardPoints = queryParams.get('points');
  const size = queryParams.get('size');
  const navigate = useNavigate();

  const userJson = localStorage.getItem('user');
  const user = JSON.parse(userJson);
  const token = localStorage.getItem('token');
  const userId = user?.id;

  useEffect(() => {
    const fetchPoints = async () => {
      try {
        const response = await axios.get(`${API_PATH}/user/${userId}/points`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setPoints(response.data.points);
      } catch (error) {
        console.error('Error fetching points:', error);
      }
    };

    fetchPoints();
  }, [userId, token]);

  const userName = user.name;
  const userLastname = user.lastname;
  const userPhone = user.phone_number;
  const userNip = user.nip;
  const userCompanyName = user.company_name;
  const userStreet = user.street_name;
  const userZipCode = user.zip_code;
  const userCity = user.city;
  const userEmail = user.email;
  const userStreetNumber = user.street_number;

  const initialValues = {
    settlement_of_the_award: 'natural person',
    name: '',
    last_name: '',
    email: '',
    phone_number: '',
    nip: '',
    company_name: '',
    zip_code: '',
    city: '',
    street: '',
    street_number: '',
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Wymagane pole'),
    last_name: Yup.string().required('Wymagane pole'),
    email: Yup.string().email('Nie poprawny adres e-mail').required('Wymagane pole'),
    phone_number: Yup.string()
      .matches(/^\d{9}$/, 'Numer telefonu musi składać się z dokładnie 9 cyfr')
      .required('Wymagane pole'),
    nip: Yup.string()
      .matches(/^\d{10}$/, 'NIP musi składać się z dokładnie 10 cyfr')
      .required('Wymagane pole'),
    company_name: Yup.string().required('Wymagane pole'),
    zip_code: Yup.string().matches(/^\d{2}-\d{3}$/, 'Poprawny format kodu pocztowego to (XX-XXX)').required('Wymagane pole'),
    city: Yup.string().required('Wymagane pole'),
    street: Yup.string().required('Wymagane pole'),
    street_number: Yup.string().required('Wymagane pole'),
  });

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    const formattedDate = new Date().toISOString().split('T')[0];

    // Fetch the award details
    try {
      const awardResponse = await axios.get(`${API_PATH}/awards/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const award = awardResponse.data;

      // Check if the user has enough points
      const requiredPoints = award.points * quantity;
      if (points < requiredPoints) {
        setInsufficientPointsModal(true);
        setSubmitting(false);
        return;
      }

      // Submit the order
      try {
        setLoading(true);
        const response = await axios.post(`${API_PATH}/orders`, {
          order_date: formattedDate,
          user_id: userId,
          award_id: id,
          quantity: quantity,
          size: size,
          points: awardPoints,
          ...values
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (response.status === 201) {
          setLoading(false);
          setModalIsOpen(true);
        }
      } catch (error) {
        console.error('Błąd podczas składania zamówienia:', error.response.data);
        setErrors({ submit: 'Wystąpił błąd podczas składania zamówienia. Spróbuj ponownie później.' });
        setLoading(false);
      } finally {
        setSubmitting(false);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching award:', error);
      setErrors({ submit: 'Wystąpił błąd podczas składania zamówienia. Spróbuj ponownie później.' });
      setSubmitting(false);
    }
  };

  const copyCompanyData = (setFieldValue) => {
    setFieldValue('name', userName);
    setFieldValue('last_name', userLastname);
    setFieldValue('email', userEmail);
    setFieldValue('phone_number', userPhone);
    setFieldValue('nip', userNip);
    setFieldValue('company_name', userCompanyName);
    setFieldValue('zip_code', userZipCode);
    setFieldValue('city', userCity);
    setFieldValue('street', userStreet);
    setFieldValue('street_number', userStreetNumber);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    navigate(`/awards`);
  };

  const closeInsufficientPointsModal = () => {
    setInsufficientPointsModal(false);
  };

  const goBackToAward = () => {
    navigate(`/awards/${id}`);
  }

  return (
    <>
      <Header />
      <PageTitle title="Potwierdzenie danych do wysyłki" />
      <section className="application">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, handleChange, isSubmitting, errors }) => (
            <Form>
              <div className='application__inner'>
                <h1 className='application__title application__title--summary'>Potwierdzenie danych do wysyłki</h1>
                <div className='summary_wrapper'>
                  <div className='summary_col'>
                    <p className='account__content_title'>Dane firmy/oddziału</p>
                    <div className='account__content_wrapper_data'>
                      <p>{userName}</p>
                      <p>{userLastname}</p>
                      <p>{userPhone}</p>
                      <p>{userEmail}</p>
                      <p>NIP {userNip}</p>
                      <p>{userCompanyName}</p>
                      <p>ul. {userStreet} {userStreetNumber}</p>
                      <p>{userZipCode}</p>
                      <p>{userCity}</p>
                    </div>
                    <div className='summary__wrapper_form_col'>
                      <p className='account__content_title'>Rozliczenie nagrody</p>
                      <div className='register__radio_wrapper'>
                        <div className='register__radio'>
                          <Field type='radio' name='settlement_of_the_award' value='natural person' id='type-owner' />
                          <label htmlFor='type-owner'>rozliczenie na osobę fizyczną</label>
                        </div>
                        <div className='register__radio'>
                          <Field type='radio' name='settlement_of_the_award' value='company' id='type-employee' />
                          <label htmlFor='type-employee'>rozliczenie na firmę</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='summary_col'>
                    <p className='account__content_title'>Dane do wysyłki tego produktu</p>
                    <button
                      type="button"
                      className='summary_button'
                      onClick={() => copyCompanyData(setFieldValue)}
                    >
                      Skopiuj dane mojej firmy
                    </button>
                    {/* form */}
                    <div className='summary_form'>
                      <div className='register__input_wrapper'>
                        <Field className='register__input' type='text' name='name' placeholder='Imię' />
                        <ErrorMessage name='name' component='div' className='register__error' />
                      </div>
                      <div className='register__input_wrapper'>
                        <Field className='register__input' type='text' name='last_name' placeholder='Nazwisko' />
                        <ErrorMessage name='last_name' component='div' className='register__error' />
                      </div>
                      <div className='register__input_wrapper'>
                        <Field
                          className='register__input'
                          type='text'
                          name='nip'
                          placeholder='NIP firmy'
                          value={values.nip}
                          onChange={(e) => {
                            const input = e.target.value.replace(/[^\d]/g, ''); // Usuń wszystkie niedozwolone znaki
                            setFieldValue('nip', input);
                          }}
                        />
                        <ErrorMessage name='nip' component='div' className='register__error' />
                      </div>
                      <div className='register__input_wrapper'>
                        <Field className='register__input' type='text' name='company_name' placeholder='Nazwa firmy' />
                        <ErrorMessage name='company_name' component='div' className='register__error' />
                      </div>
                      <div className='register__input_wrapper'>
                        <Field
                          className='register__input'
                          type='text'
                          name='zip_code'
                          placeholder='Kod pocztowy'
                          value={values.zip_code}
                          onChange={(e) => {
                            const input = e.target.value.replace(/[^\d]/g, ''); // Usuń wszystkie niedozwolone znaki
                            let formatted = '';
                            if (input.length > 2) {
                              formatted = input.slice(0, 2) + '-' + input.slice(2, 5);
                            } else {
                              formatted = input;
                            }
                            setFieldValue('zip_code', formatted);
                          }}
                        />
                        <ErrorMessage name='zip_code' component='div' className='register__error' />
                      </div>
                      <div className='register__input_wrapper'>
                        <Field className='register__input' type='text' name='city' placeholder='Miasto' />
                        <ErrorMessage name='city' component='div' className='register__error' />
                      </div>
                      <div className='register__input_wrapper'>
                        <Field className='register__input' type='text' name='street' placeholder='Ulica' />
                        <ErrorMessage name='street' component='div' className='register__error' />
                      </div>
                      <div className='register__input_wrapper'>
                        <Field className='register__input' type='text' name='street_number' placeholder='Nr budynku i lokalu' />
                        <ErrorMessage name='street_number' component='div' className='register__error' />
                      </div>
                    </div>
                    <div className='summary_position_button'>
                      <button
                        type="submit"
                        className='summary_button'
                        disabled={isSubmitting}
                      >
                        {loading ? <div className="loader"></div> : 'Złóż zamówienie'}
                      </button>
                      <button
                        type="button"
                        className='summary_button_back'
                        onClick={() => goBackToAward()}
                      >
                        <img src={ArrowLeftImg} alt="Arrow back" />
                        powrót
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </section>
      <Footer />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Notification"
        className="Modal"
        overlayClassName="Overlay"
      >
        <p><strong>Udało się!</strong></p>
        <p>Dziękujemy za wybór nagrody i prosimy o jeszcze chwilę cierpliwości.</p>
        <p><strong>Wysyłka będzie realizowana <br />w dniach 4-18.11.2024.</strong></p>
        <p>Poinformujemy cię, kiedy twoja nagroda będzie w drodze.</p>
        <button onClick={closeModal}>powrót do listy nagród</button>
      </Modal>
      <Modal
        isOpen={insufficientPointsModal}
        onRequestClose={closeInsufficientPointsModal}
        contentLabel="Insufficient Points"
        className="Modal"
        overlayClassName="Overlay"
      >
        <p><strong>Masz za mało punktów, aby zamówić tę nagrodę.</strong></p>
        <button onClick={closeInsufficientPointsModal}>ok</button>
      </Modal>
    </>
  );
};

export default Summary;